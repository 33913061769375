// @flow

import { combineReducers } from 'redux';

import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import { AdminDashboard, AdminDashboardState } from './adminView/reducers';

const rootReducer = combineReducers({
    Auth,
    AppMenu,
    Layout,
    AdminDashboard,
});
export default rootReducer;
type ReducerReturnType = ReturnType<typeof rootReducer> ;
export type RootState = {
	Auth: ReducerReturnType['Auth'],
	AppMenu: ReducerReturnType['AppMenu'],
	Layout: ReducerReturnType['Layout'],
	AdminDashboard: AdminDashboardState
}