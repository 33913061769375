import Bugsnag from "@bugsnag/browser";
import { contractorInvoiceStatus, monthNamesArray } from "../../helpers/index";
import { Contractor, ContractorMonth, ContractorMonthState, ProcessedMonth, Rate, StateContractor } from "../../types";

export const enforceDateFormat = (supposedDate: string) => (supposedDate && supposedDate.length !== 10 ? supposedDate.substring(0,10) : supposedDate);
export const getDateFromFormattedStr = (strDate: string) => {
	try {
        const year = Number(strDate.substring(0, 4));
        const month = Number(strDate.substring(5, 7));
        const date = Number(strDate.substring(8, 10));
        let hours = 0;
        let minutes = 0;
        if (strDate.length >= 16) {
            hours = Number(strDate.substring(11, 13));
            minutes = Number(strDate.substring(14, 16));
        }
        return new Date(year, month - 1, date, hours, minutes);
    } catch (error) {
		return null;
	}
};
export const mapContractorFn = (contractor:Contractor) => {
    const candidate = contractor.candidate || {};
    const margin = contractor.charge_rate - contractor.hourly_rate;
    //Here we format the contractor object received from the back for the whole app
    return{
        ...contractor,
        email : candidate.candidate_email,
        first_name: typeof contractor.first_name === 'string' && contractor.first_name.trim() !== '' ? contractor.first_name : candidate.candidate_first_name,
        full_name: candidate.candidate_full_name,
        last_name: candidate.candidate_last_name,
        photo: candidate.photo,
        start_date: enforceDateFormat(contractor.start_date || ""),
        date_of_birth: enforceDateFormat(contractor.date_of_birth || ""),
        end_date: enforceDateFormat(contractor.end_date || ""),
		current_contract_end_date: enforceDateFormat(contractor.current_contract_end_date || ""),
        payment_method: contractor.payment_method?.trim(),
        client_name: contractor.client?.client_name || "",
        margin,
    }
};
export const mapContractorMonthFn = (contractorMonth: ContractorMonth & { relatedRecordId?: number | null}) => {
    const contractor = contractorMonth.contractor || {};
	const candidate = contractor.candidate || {};
	const client = contractorMonth.client || {};
    const status = contractorInvoiceStatus[contractorMonth.contractor_invoice_status_id] || 'Not uploaded';
    const margin = contractorMonth.charge_rate - contractorMonth.hourly_rate;
    //Here we format the contractor object received from the back for the whole app
    const mapped: ContractorMonthState = {
        ...contractorMonth,
        email : candidate.candidate_email,
        first_name: candidate.candidate_first_name,
        full_name: candidate.candidate_full_name,
        last_name: candidate.candidate_last_name,
        candidate_id: candidate.candidate_id,
        photo: candidate.photo,
        payment_method: contractor.payment_method?.trim(),
        client_name: client?.client_name,
        contractor_invoice_status: status,
        margin,
        monthly_margin: (contractorMonth.number_of_hours || 0) * margin,
        contractor:{
            ...contractor,
            start_date: enforceDateFormat(contractor.start_date || ""),
            date_of_birth: enforceDateFormat(contractor.date_of_birth || ""),
            end_date: enforceDateFormat(contractor.end_date || ""),
			current_contract_end_date: enforceDateFormat(contractor.current_contract_end_date || ""),
        },
        candidate,
		hasTwoRecordsForMonth: contractorMonth.hasTwoRecordsForMonth,
		// @ts-ignore
		hasLowerRate: contractorMonth.hasLowerRate,
		relatedRecordId: contractorMonth.relatedRecordId,
    }
	return mapped;
};

export const addNewCMs = (allContractors: StateContractor[], newCMs: ContractorMonth[]) => {
    const updatedObj = allContractors.map( con => {
        const contractorNewCMs = newCMs.filter( cm => cm.contractor_id === con.contractor_id ); //Filtering the new cms to get this contractor's new ones
		const existingCms = con.contractor_month || [];
        const updatedCms = [ ...contractorNewCMs, ...existingCms ]; //creating an array with the new cms + the existing ones
        return ({
            ...con,
            contractor_month: updatedCms //Returning the new + existing cms
        });
    });
    return updatedObj;
}
export const sortRates = (rates: Rate[]) => {
    const copyRates = [...rates];
    copyRates.sort((r1, r2) => r1.effective_date < r2.effective_date ? 1: -1);
    return copyRates;
}
export const sortContractorMonths = (contractorMonths: ContractorMonthState[]) => {
    const cms = [...contractorMonths];
    cms.sort(
        (c1, c2) => {
			if(!c1.contractor?.start_date || !c2.contractor?.start_date){
				return +1;
			}
			if (c1.contractor?.start_date < c2.contractor?.start_date) {
				return -1;
			}
			else if (c1.contractor.start_date === c2.contractor.start_date) {
				if (c1.contractor_id < c2.contractor_id) {
					return -1;
				} else if (c1.contractor_id > c2.contractor_id) {
					return +1;
				} else {
					return (c1.hourly_rate < c2.hourly_rate ? -1 : +1);
				}
			} else {
				return +1;
			}
		}
    );
	for(let i = 0; i < cms.length; i++){
		const cm = cms[i];
		if(cm.hasTwoRecordsForMonth && cm.relatedRecordId){
			const relatedCm = cms.find(cm2 => cm2.contractor_month_id === cm.relatedRecordId);
			if(relatedCm?.hasLowerRate === cm.hasLowerRate){
				cms[i].hasLowerRate = true;
			}
		}
	}
    return cms;
}

export const sortContractors = (contractors: StateContractor[]) => {
    const contractorsCopy = [...contractors];
    contractorsCopy.sort(
        (c1, c2) => {
			if(!c1.start_date || !c2.start_date){
				return +1;
			}
			return (c1.start_date < c2.start_date ? -1 : +1);
		}
    );
    return contractorsCopy;
}
export const getEurToUsdRate = (rates: Rate[]) => rates.find(r => r.from_currency === 'EUR' && r.to_currency === 'USD');

export const getProcessedMonths = (processedMonths: ProcessedMonth[]) => (
    processedMonths.map( pm =>({...pm, monthName: monthNamesArray[ pm.month-1 ]}) )
);
export const getCurrentUsdEurExchangeRate = async() => {
    let exchangeRate;
    let rateExp;
    const now = new Date();
    try {
		const rate = localStorage.getItem('rate')
		if(!rate){
			throw new Error('no rate in local storage');
		}
        exchangeRate = JSON.parse(rate);
		if(exchangeRate){
        	rateExp = new Date(exchangeRate.createdDate);
        	rateExp.setHours( rateExp.getHours() + 1 );
		}
    } catch (err) {
		const error = err as Error;
		console.log('error on getting rate from local', error);
		Bugsnag.notify(error);
	}
    if ( !exchangeRate || !rateExp || now > rateExp ){
        try {
            const res = await fetch( `https://currencyapi.net/api/v1/rates?key=${process.env.REACT_APP_CURRENCY_API_KEY}&output=JSON` );
            const data = await res.json();
            exchangeRate = { createdDate: new Date(), rate: data.rates.EUR };
            localStorage.setItem('rate', JSON.stringify(exchangeRate));    
        } catch (err) {
			const error = err as Error;
			console.log('getting from currency api error', error);
			Bugsnag.notify(error);
		}
    }
    return exchangeRate?.rate || 0.95;
};
