import Bugsnag from "@bugsnag/js";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

import { CategoryType, Contractor, CreateHotListRecord, CreatingJob, JobType, PayMethodType } from "../types";
import axiosClient from "./Axios";

const formDataReqOptions = {
	headers: {
		'Content-Type': 'multipart/form-data'
	}
};

export const getJobs = (queryString: string) => axiosClient.get(`/jobs?${queryString}`).then( res => res.data );
export const getSubcategories = () => axiosClient.get('/subcategories').then( res => res.data );
export const getExpenses = () => axiosClient.get('/expenses/all').then(res => res.data);
export const deleteExpense = (expense_id: number) => axiosClient.delete(`/expense/${expense_id}`).then(res => res.data);
export const getHotlist = (queryString: string) => axiosClient.get(`/candidatehotlist?${queryString}`).then( res => res.data );
export const getExpenseCategories = () => axiosClient.get('/expensecategories').then(res => res.data);
export const createExpenseCategory = (category: CategoryType) => axiosClient.post(`/expensecategory`, category).then(res => res.data);
export const updateExpenseCategory = (id: number, category: CategoryType) => axiosClient.put(`/expensecategory/${id}`, category).then(res => res.data);
export const updateJob = (id: number, job: JobType) => axiosClient.put(`/job/${id}`, job).then(res => res.data);
export const createJob = (job: CreatingJob) => axiosClient.post(`/job`, job).then(res => res.data);

export const getExpensePayMethods = () => axiosClient.get('/expensepaymethod').then(res => res.data);
export const createExpensePayMethods = (pay_method: PayMethodType) => axiosClient.post(`/expensepaymethod`, pay_method).then(res => res.data);
export const updateExpensePayMethods = (id: number, pay_method: PayMethodType) => axiosClient.put(`/expensepaymethod/${id}`, pay_method).then(res => res.data);

export const getRecruiterMonths = (params: string) => axiosClient.get(`/recruitermonths/all?${params}`).then(res => res.data);
export const getRecruiters = (params: string) => axiosClient.get(`/recruiters?${params}`).then(res => res.data);

export const getRevolutAuthUrl = () => axiosClient.get(`/auth/revolut/url`).then(res => res.data);
export const getRevolutPayment = (paymentId: string) => axiosClient.get(`/revolut/payment/${paymentId}`).then(res => res.data);

export const getClientTemplates = (params: string) => axiosClient.get(`/clienttemplates?${params}`).then(res => res.data);
export const deleteClientTemplate = (templateId: number) => axiosClient.delete(`/clienttemplate/${templateId}`).then(res => res.data);

export const getClientPaperwork = (params: string) => axiosClient.get(`/clientpaperwork?${params}`).then(res => res.data);
export const deleteClientPaperwork = (paperworkId: number) => axiosClient.delete(`/clientpaperwork/${paperworkId}`).then(res => res.data);

export const getContractorPaperwork = (params: string) => axiosClient.get(`/contractorpaperwork?${params}`).then(res => res.data);
export const getContractorWithPaperwork = (params: string) => axiosClient.get(`/contractor/withPaperwork?${params}`).then(res => res.data);
export const deleteContractorPaperwork = (paperworkId: number) => axiosClient.delete(`/contractorpaperwork/${paperworkId}`).then(res => res.data);

export const getCountries = () => axiosClient.get(`/countries`).then(res => res.data);
export const getCandidateDocTypes = () => axiosClient.get(`/candidatedocumenttypes`).then(res => res.data);
export const getClientDocTypes = () => axiosClient.get(`/clientdocumenttypes`).then(res => res.data);
export const getClientDocuments = (params: string) => axiosClient.get(`/clientdocuments?${params}`).then(res => res.data);
export const getCandidatesByName = (name: string) => axiosClient.get(`/candidate/byName/${encodeURIComponent(name)}`).then(res => res.data);
export const getCandidateDocsByCandidateId = (id: number) => axiosClient.get(`/candidatedocuments?candidate_id=${id}`).then(res => res.data);
export const getContractorChecks = (params: string) => axiosClient.get(`/contractrorchecks?${params}`).then(res => res.data);
export const getBcProfiles = (params: string) => axiosClient.get(`/clientbcprofiles?${params}`).then(res => res.data);
export const getBackgroundChecks = () => axiosClient.get(`/backgroundchecks`).then(res => res.data);
export const getContractorMonths = (params: string) => axiosClient.get(`/contractormonths?${params}`).then(res => res.data);
export const getContractors = (params: string) => axiosClient.get(`/contractors?${params}`).then(res => res.data);

export const editContractor = async (con: any, candidateId: number, contractorId: number ,
	// updateParentTable: Function,
) => { //TODO: Fix this to use actual contractor type here
	const contractor = {
		...con,
		client_id: Number(con.client_id),
		candidate_id: candidateId,
		date_of_birth: con.date_of_birth?.length === 10 ? con.date_of_birth : null,
		// end_date: con.end_date?.length === 10 ? con.end_date : null,
		current_contract_end_date: con.current_contract_end_date?.length === 10 ? con.current_contract_end_date : null,
	};
	try {
		const con = await axiosClient.put<Contractor>(`/contractor/${contractorId}`, contractor);
		Swal.fire({title: 'Contractor updated!', icon: 'success'}).then( () => {
			// updateParentTable(); //Update parents table
		});
		return con.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log('error updating contractors', err, err.response, err.response?.data);
		Bugsnag.notify(err);
		Swal.fire({title: 'Error', text: 'There was an error updating contractors, please try again', icon: 'error'});
		return null;
	}
};

export const updateCandidate = (candidateId: number, candidateData: any) => axiosClient.put(`/candidate/${candidateId}`, candidateData).then(res => res.data);

export const updateClientPaperwork = (id: number, data: FormData) => axiosClient.put(`/clientpaperwork/${id}`, data, formDataReqOptions).then(res => res.data);
export const updateContractorPaperwork = (id: number, data: FormData) => axiosClient.put(`/contractorpaperwork/${id}`, data, formDataReqOptions).then(res => res.data);

export const updateContractorCheck = (id: number, data: FormData) => axiosClient.put(`/contractorbackgroundcheck/${id}`, data, formDataReqOptions).then(res => res.data);

export const getInvoiceData = async () => {
	try {
		const { data } = await axiosClient.get(`/admin/invoices`);
		return Array.isArray(data) ? data : [];
 	} catch (error) {
		return [];
	}
};

export const addChecksWithProfile = async (contractorId: number, clientProfileId: number) => 
	axiosClient.post(`/contractor/${contractorId}/checksWithProfile/${clientProfileId}`).then(res => res.data);
export const addContractorChecks = async (contractorId: number, checkId: number, formData: FormData) =>
	axiosClient.post(`/contractor/${contractorId}/backgroundcheck/${checkId}`, formData, formDataReqOptions);

export const addHotlistRecord = async (data: CreateHotListRecord) =>
	axiosClient.post(`/candidatehotlist`, data).then(res => res.data);

export const deleteHotlistRecord = (id: number) => axiosClient.delete(`/candidatehotlist/${id}`).then(res => res.data);

export const deleteContractorCheck = async (id: number) => axiosClient.delete(`/contractorbackgroundcheck/${id}`).then(res => res.data);
export const createManualClientInvoice = async (formData: FormData) => axiosClient.post('/clientinvoice/withfile', formData, formDataReqOptions).then(res => res.data);
export const getCandidateDocById = async (id: number) => axiosClient.get(`/candidatedocuments?candidate_document_id=${id}`).then(
	res => res.data[0]
	// Array.isArray(res.data) && res.data.length ? res.data[0] : null
);
export const reactivateContractor = async (id: number, data: any) => axiosClient.post(`/contractor/${id}/reactivate`, data).then(res => res.data);
export const getContractorPaperworkById = async (id: number) => axiosClient.get(`/contractorpaperwork/${id}`).then(
	res => res.data
);

export const getAuditLogs = async (reqBody: object) => axiosClient.post(`/audit/search`, reqBody).then(res => res.data);

export const getReferrals = (params: string) => axiosClient.get(`/referral?${params}`).then(res => res.data);
export const getJobApplications = (params: string) => axiosClient.get(`/candidatejobapplication?${params}`).then(res => res.data);
export const defaultQueryConfig = {
	refetchOnWindowFocus: false,
	retry: false,
	// retry: (failureCount, err: any) => {
	// 	const error = err as AxiosError;
	// 	return !([402, 403].includes(error.response?.status || 0));
	// }
};

export const getFrontEndRoles = (params: string) => axiosClient.get(`/frontendroles?${params}`).then(res => res.data);
// export const getNotifications = (params: string) => axiosClient.get(`/notifications?${params}`).then(res => res.data);
export const getNotificationsByUser = (params: string) => axiosClient.get(`/usernotifications/byUser?${params}`).then(res => res.data);
export const getNotificationTypes = () => axiosClient.get(`/notificationtypes`).then(res => res.data);